import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';

const apayApplicationType = [
  { value: 1, label: '模具费' },
  { value: 2, label: '打样费' },
  { value: 3, label: '原材料' },
  { value: 4, label: '预付款' },
  { value: 5, label: '借款' },
  { value: 6, label: '内部转账' },
  { value: 7, label: '其他' }
];
const apayPaymentType = [
  { value: 1, label: '普通' },
  { value: 2, label: '紧急' }
];
export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '申请单号', prop: 'apay_no', itemType: 'input', input: false, sortable: true, widthAuto: true },
  {
    label: '申请类型',
    prop: 'apay_application_type',
    itemType: 'select',
    options: apayApplicationType,
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => apayApplicationType.find(({ value, label }) => value === val)?.label
  },
  {
    label: '付款类型',
    prop: 'apay_payment_type',
    itemType: 'select',
    options: apayPaymentType,
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => apayPaymentType.find(({ value }) => value === val)?.label
  },
  {
    label: '是否需要凭证',
    prop: 'apay_voucher',
    itemType: 'select',
    options: [
      { value: 1, label: '是' },
      { value: 2, label: '否' }
    ],
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => (val === 1 ? '是' : '否')
  },
  {
    label: '是否有发票',
    prop: 'apay_invoice',
    itemType: 'select',
    options: [
      { value: 1, label: '是' },
      { value: 2, label: '否' }
    ],
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => (val === 1 ? '是' : '否')
  },
  { label: '金额', prop: 'apay_total', itemType: 'input', input: false, sortable: true, widthAuto: true },
  { label: '发票号码', prop: 'apay_actu_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '付款理由', prop: 'apay_reason', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '收款单位',
    prop: 'apay_payee',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: true
  },
  {
    label: '付款单位',
    prop: 'cptt_id',
    itemType: 'select',
    input: true,
    sortable: false,
    widthAuto: true,
    options: [],
    needOtherColumn: true,
    formatter: val => val.cptt_name,
    overflowTooltip: true
  },
  store.state.stff_name,
  {
    prop: 'create_time',
    label: '录入时间',
    input: false,
    itemType: 'date',
    labelWidth: '120px',
    formatter: val => getDateNoTime(val, true)
  },
  store.state.status
];
export const unitTableProperties = [
  store.state.index,
  { label: '收款单位', prop: 'inst_cname', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '开户行', prop: 'inst_bank', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '银行账号', prop: 'inst_code', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
export const sconNoTableProperties = [
  { label: '外销合同号', prop: 'scon_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户订单号', prop: 'scon_cust_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '合同金额', prop: 'scon_total', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '币种', prop: 'cust_currency', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '公司抬头', prop: 'cptt_aname', itemType: 'input', input: true, sortable: false, widthAuto: true }
];
